import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MapServiceService {
  private fitBoundsListener = new Subject<boolean>();
  private oldInc = true;
  private newInc = true;

  constructor() {}

  getFitBoundsListener() {
    return this.fitBoundsListener.asObservable();
  }

  setFitBounds() {
    this.fitBoundsListener.next(this.newInc || this.oldInc);
  }

  setNewIncid(value) {
    this.newInc = value;
  }

  setOldIncid(value) {
    this.oldInc = value;
  }
}
