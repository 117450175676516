import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy1LvlAttributes'
})
export class FilterBy1LvlAttributesPipe implements PipeTransform {

  // transform(values: Array<any>, args:any[]):any {
  //     return values.filter((value) => {
  //         for (let i = 0; i < args.length; i++) {
  //             if (value[args[i][0]] != args[i][1]) {
  //                 return false;
  //             }
  //         }
  //         return true;
  //     });
  // }
  // // <div *ngFor="let elm of arr | filterBy1LvlAttributes:[['type', 'B'], ['name', 'bbb']]">
  // //   <span>Name: {{elm.name}}</span> | <span>Type: {{elm.type}}</span>
  // // </div>


  transform(items: any, filter: any): any {
    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);
      return items.filter(item =>
          filterKeys.reduce((memo, keyName) =>
              (memo && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] === "", true));
    } else {
        return items;
    }
  }
  // <input class="form-control" type="text" [value]="filterText" [placeholder]="filterPlaceholder" [formControl]="filterInput" />
  // <div>
  //   <span>You typed this:</span><span class="bold" [innerHtml]="filterText"></span>
  // </div>
  // <ul>
  //   <li *ngFor="let item of _items | filter:{label: filterText}">{{ item.label }}</li>
  // </ul>


}
