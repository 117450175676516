import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AgmCoreModule } from "@agm/core";
import { FormsModule } from "@angular/forms";
import { AgmJsMarkerClustererModule } from "@agm/js-marker-clusterer";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
// import { AppComponent } from './app.component';
import { FilterByAttributePipe } from "./pipes/filter-by-attribute.pipe";
import { FilterByInnerAttributePipe } from "./pipes/filter-by-inner-attribute.pipe";

// import { MatDatepickerModule, MatMomentDateModule, MatNativeDateModule } from '@angular/material';

// import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatCardModule,
  MAT_DATE_LOCALE,
} from "@angular/material";

import { FilterByUniqueAttributePipe } from "./pipes/filter-by-unique-attribute.pipe";
import { FilterBy1LvlAttributesPipe } from "./pipes/filter-by1-lvl-attributes.pipe";
import { OnInitAtNgForDirective } from "./shared/on-init-at-ng-for.directive";
import { RecaptchaDirective } from "./directives/recaptcha.directive";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
// import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { RecaptchaModule } from "ng-recaptcha";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
// import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from "ng-recaptcha";

import { AgmMarkerSpiderModule } from "agm-oms";

import { MatDatepickerModule, MatNativeDateModule } from "@angular/material";

@NgModule({
  declarations: [
    AppComponent,
    FilterByAttributePipe,
    FilterByInnerAttributePipe,
    FilterByUniqueAttributePipe,
    FilterBy1LvlAttributesPipe,
    OnInitAtNgForDirective,
    RecaptchaDirective,
  ],
  imports: [
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAtUoqSVXfNfm3Dg3zqzxWarKexujZCaNU",
    }),
    AgmJsMarkerClustererModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatDatepickerModule,
    // MatMomentDateModule,
    MatNativeDateModule,
    // RecaptchaModule,  //this is the recaptcha main module
    // RecaptchaFormsModule, //this is the module for form incase form validation
    RecaptchaV3Module,
    RecaptchaModule.forRoot(),

    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    AgmMarkerSpiderModule,

    // MatDatepickerModule,        // <----- import(must)
    // MatNativeDateModule,        // <----- import for date formating(optional)

    BrowserAnimationsModule,
    FontAwesomeModule,
    LeafletModule.forRoot(),
  ],
  exports: [RecaptchaModule],
  providers: [
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LdJauAUAAAAAKhKL1zSx6Eino94hclRPpHAt_kW' },
    // { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: '6LdJauAUAAAAAKhKL1zSx6Eino94hclRPpHAt_kW' } as RecaptchaSettings },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: "6LebbuYUAAAAAN00DBmjr3FWhf9aIhQh0DWTAEJl",
      } as RecaptchaSettings,
    },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
  // declarations: [ AppComponent ],
  bootstrap: [AppComponent],
  // schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {}

// GOGOLE KEY: AIzaSyAtUoqSVXfNfm3Dg3zqzxWarKexujZCaNU

// https://angular-maps.com/guides/getting-started/   (https://www.npmjs.com/package/@agm/core)
// https://www.npmjs.com/package/@google/markerclusterer  (https://medium.com/@s.delecroix/how-to-use-markercluster-with-googlemap-in-an-angular-app-9b955e669399)

// "@fortawesome/fontawesome-svg-core": "^1.2.25",
// "@fortawesome/free-solid-svg-icons": "^5.11.2",

// "@fortawesome/angular-fontawesome": "^0.3.0",
// "@fortawesome/fontawesome-svg-core": "^1.2.18",  ok
// "@fortawesome/free-brands-svg-icons": "^5.9.0",
// "@fortawesome/free-regular-svg-icons": "^5.9.0",
// "@fortawesome/free-solid-svg-icons": "^5.8.2",   ok

// "@fortawesome/angular-fontawesome": "^0.5.0",
// "@fortawesome/fontawesome-svg-core": "^1.2.25",
// "@fortawesome/free-brands-svg-icons": "^5.11.2",
// "@fortawesome/free-regular-svg-icons": "^5.11.2",
// "@fortawesome/free-solid-svg-icons": "^5.11.2",
