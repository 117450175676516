import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByAttribute',
  pure: false
})
export class FilterByAttributePipe implements PipeTransform {

  // | filterByAttribute:[['poisonLocationInfo.country', 'B'], ['poisonLocationInfo.county', 'county']]

  // FILTER BY ATTRIBUTES
  // transform(values: Array<any>, args:any[]):any {
  //   return values.filter((value) => {
  //       for (let i = 0; i < args.length; i++) {
  //           if (value[args[i][0]] != args[i][1]) {
  //               return false;
  //           }
  //       }
  //       return true;
  //   });
  // }


  transform(items: any[], filter: any): any {
    // console.log("MPAINO FILTERS_new");
    // console.log(items);
    // console.log(filter);
    var filterIsEmpty = true;
    for(var prop in filter) {
      if(filter.hasOwnProperty(prop)) filterIsEmpty = false;
    }
    if (!items || !filter || filterIsEmpty  ) {
        return items;
    } else {
      var filterIsEmpty2 = true;
      for (let i=0;i<filter.length;i++){
        if(filter[i].value && filter[i].value.toString()!='') {
          var filterIsEmpty2 = false;
          if(!isNaN(filter[i].value)) {
            filter[i].value = filter[i].value.toString();
          } else {
            filter[i].value = filter[i].value.toLowerCase();
          }
        }
      }
      if ( filterIsEmpty2 ) return items;
      return items.filter(function(item){
        let helpVar = true;

        // for (const [key, value] of Object.entries(filter)) {
        //   // console.log(key,value);
        // //     if(isEmpty(filter[key])){
        // //       continue;
        // //     } else {
        // //       // console.log(filter[key]);
        // //       // console.log(item[key]);
        // //       if(filter[key]!=item[key]){
        // //         helpVar = false;
        // //         break;
        // //       }
        // //       if (!helpVar) break;
        // //     }
        // // }
        // // if (helpVar) return item;
        // }
        let uniqueAttributesTable = [];
        let uniqueAttributesJson = {};
        for (let i=0; i<filter.length; i++) {
          // console.log(filter[i]);
          if(filter[i].depth==0){
            if( !filter[i].value || filter[i].value=='' ) {helpVar=true; continue;}
            if(!filter[i].filterType || filter[i].filterType=='textmatch'){
              // console.log("test", item[filter[i].parents[0]][filter[i].child] , filter[i].value);
              if(!isNaN(filter[i].value)) {
                if ( !(item[filter[i].child].toString()).includes(filter[i].value) ) helpVar = false;
              } else {
                if( !(item[filter[i].child].toLowerCase()).includes(filter[i].value) ) helpVar = false;
              }
            }
            // else if ( !filter[i].filterType || filter[i].filterType=='uniqueSubcategory' ){
            //   if(!uniqueAttributesJson[item[filter[i].uniqueAttribute]]) {
            //     uniqueAttributesJson[item[filter[i].uniqueAttribute]] = true;
            //     uniqueAttributesTable.push(uniqueAttributesJson[item[filter[i].uniqueAttribute]]);
            //   }
            // }
          } else if (filter[i].depth==1) {
            if( !filter[i].value || filter[i].value=='' ) {helpVar=true; continue;}
            if(!filter[i].filterType || filter[i].filterType=='textmatch'){
              // console.log( "check", filter[i] , filter[i].parents , filter[i].parents[0] );
              if ( !item[filter[i].parents[0]] || item[filter[i].parents[0]][filter[i].child]==null ) {
                helpVar = false;
              } else {
                // console.log("test", item[filter[i].parents[0]][filter[i].child] , filter[i].value);
                if(!isNaN(filter[i].value)) {
                  if ( !(item[filter[i].parents[0]][filter[i].child].toString()).includes(filter[i].value) ) helpVar = false;
                } else {
                  if( !(item[filter[i].parents[0]][filter[i].child].toLowerCase()).includes(filter[i].value) ) helpVar = false;
                }
              }
            }
          } else if (filter[i].depth==2) {

          }
          if (!helpVar) break;
        }
        if (helpVar) return item;
      });
    }
  }


  // FILTER BY INCEPTED ATTRIBUTES
  // private searchedItems: Array<any> = [];
  // private key: string;
  // private prop: string;
  // private childrenPropName: string;
  //
  // transform(value: any, key?: any, prop?: any, childrenProp?: any): any {
  //   if(key != undefined) {
  //     this.searchedItems = [];
  //     this.key = key.toLowerCase();
  //     this.prop = prop;
  //     this.childrenPropName = childrenProp;
  //     let searchResult = this.searchRecursive(value);
  //     return searchResult;
  //   }
  //   return value;
  // }
  //
  // searchRecursive(value) {
  //   for(var i = 0; i < value.length; i++) {
  //     let lowerCaseName = value[i][this.prop].toLowerCase();
  //     if(lowerCaseName.includes(this.key)) {
  //       this.searchedItems.push(value[i]);
  //     } else if(value[i][this.childrenPropName]) {
  //       if(value[i][this.childrenPropName].length > 0) {
  //         this.searchRecursive(value[i][this.childrenPropName]);
  //       }
  //     }
  //   }
  //
  //   return this.searchedItems;
  // }


}
