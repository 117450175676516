import { Directive, Output, EventEmitter, Input, SimpleChange } from '@angular/core';

@Directive({
  selector: '[appOnInitAtNgFor]'
})
export class OnInitAtNgForDirective {

  @Output() appOnInitAtNgFor: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
     this.appOnInitAtNgFor.emit('dummy');
     // console.log("AAAAAAAA");
  }

}
