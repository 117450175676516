export interface ReCaptchaConfig {
  theme? : 'dark' | 'light';
  type? : 'audio' | 'image';
  size? : 'compact' | 'normal';
  tabindex? : number;
}

import { Directive } from '@angular/core';
import { Input } from '@angular/core';

@Directive({
  selector: '[appRecaptcha]'
})
export class RecaptchaDirective {

  @Input() key : string;
  @Input() config : ReCaptchaConfig = {};
  @Input() lang : string;

  constructor() { }

}
