import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByUniqueAttribute',
  pure: false
})
export class FilterByUniqueAttributePipe implements PipeTransform {

  transform(items: any[], filter: any): any {
    // console.log("MPAINO FILTERS");
    // console.log(items);
    // console.log(filter);
    if (!items)return items;
    let uniqueAttributesJson = {};
    return items.filter(function(item){
      let helpVar = true;
      if(!uniqueAttributesJson[item[filter.uniqueAttribute]]) {
        uniqueAttributesJson[item[filter.uniqueAttribute]] = true;
        return item;
      }
    });
  }
}
