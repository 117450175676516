import { Pipe, PipeTransform } from "@angular/core";
import { MapServiceService } from "../map-service.service";

// https://bearnithi.com/2019/02/14/recursive-search-pipe-nested-object-search-angular/   // --> SOS SOS
// https://www.freecodecamp.org/news/15-useful-javascript-examples-of-map-reduce-and-filter-74cbbb5e0a1f/   // --> SOS SOS
// http://bearnithi.com/bn-ng-components/bn-ng-tree/   // --> SOS SOS

@Pipe({
  name: "filterByInnerAttribute",
  pure: true,
})
export class FilterByInnerAttributePipe implements PipeTransform {
  constructor(private mapService: MapServiceService) {}

  transform(items: any[], filter: any): any {
    var filterIsEmpty = true;
    // console.log("filter", filter);
    if (filter[13].value && !filter[14].value) {
      filter[14].value = new Date();
      // console.log("Filter 2", filter);
    }

    for (var prop in filter) {
      if (!filter[prop] || !filter[prop].value) continue;
      if (
        filter[prop].filterType == "textmatch" ||
        filter[prop].filterType == "findInArray"
      ) {
        if (filter[prop].value == "") {
          continue;
        } else {
          var filterIsEmpty = false;
          if (!isNaN(filter[prop].value)) {
            filter[prop].value = filter[prop].value.toString();
          } else {
            filter[prop].value = filter[prop].value.toLowerCase();
          }
        }
      } else if (filter[prop].filterType == "greaterLowerEqualDate") {
        // console.log("++++++++++++++");
        if (filter[prop].value && filter[prop].value != "") {
          // console.log("++++++++++++++", 2);
          filterIsEmpty = false;
        }
      }
    }
    if (!items || filterIsEmpty) {
      // console.log("I return Items 1", items);
      // for (let i = 0; i < items.length; i++) {
      //   // if (items[i].areaProtectionInfo.length > 0) {
      //   //   console.log("areaProtectionInfo", items[i]);
      //   // }

      //   if (items[i].deadAnimalInfo) {
      //     if (items[i].deadAnimalInfo.info) {
      //       console.log("deadAnimalTypeInfo", items[i]);
      //     }
      //   }
      // }
      return items;
    } else {
      // return items;
      let itemsToReturn = items.filter(function (item) {
        let helpVar = true;
        let uniqueAttributesTable = [];
        let uniqueAttributesJson = {};
        for (let i = 0; i < filter.length; i++) {
          if (filter[i].depth == 0) {
            if (!filter[i].value || filter[i].value == "") {
              helpVar = true;
              // console.log(1);
              continue;
            }
            if (!filter[i].filterType || filter[i].filterType == "textmatch") {
              if (!isNaN(filter[i].value)) {
                if (!item[filter[i].child].toString().includes(filter[i].value))
                  helpVar = false;
                // console.log(2);
              } else {
                if (
                  !item[filter[i].child].toLowerCase().includes(filter[i].value)
                )
                  helpVar = false;
                // console.log(3);
              }
            } else if (filter[i].filterType == "findInArray") {
              // console.log("I runn 55");
              if (filter[i].value && filter[i].value.toString().length > 0) {
                // console.log("I runn 56", filter[i].parents[0]);

                if (filter[i].parents[0] === "deadAnimalInfo") {
                  // console.log("I runn 57");
                  if (item[filter[i].parents[0]][filter[i].attribute][0]) {
                    // console.log("I runn 58");
                    if (
                      item[filter[i].parents[0]][filter[i].attribute][0].animal
                    ) {
                      // console.log(
                      //   "I runn 59",
                      //   item[filter[i].parents[0]][filter[i].attribute],
                      //   filter[i].value
                      // );
                      if (
                        !item[filter[i].parents[0]][
                          filter[i].attribute
                        ].find((a) =>
                          a.animal
                            .toLowerCase()
                            .includes(filter[i].value.toLowerCase())
                        )
                      ) {
                        helpVar = false;
                        // console.log(44);
                      }
                    } else {
                      helpVar = false;
                      // console.log(45);
                    }
                  } else {
                    helpVar = false;
                    // console.log(46);
                  }
                } else {
                  if (!item[filter[i].attribute].includes(filter[i].value))
                    helpVar = false;
                  // console.log(4);
                }
              }
            }
          } else if (filter[i].depth == 1) {
            if (!filter[i].value || filter[i].value == "") {
              helpVar = true;
              // console.log(5);
              continue;
            }
            if (!filter[i].filterType || filter[i].filterType == "textmatch") {
              if (
                !item[filter[i].parents[0]] ||
                item[filter[i].parents[0]][filter[i].child] == null
              ) {
                helpVar = false;
              } else {
                if (!isNaN(filter[i].value)) {
                  if (
                    !item[filter[i].parents[0]][filter[i].child]
                      .toString()
                      .includes(filter[i].value)
                  )
                    helpVar = false;
                } else {
                  if (
                    !item[filter[i].parents[0]][filter[i].child]
                      .toLowerCase()
                      .includes(filter[i].value)
                  )
                    helpVar = false;
                }
              }
            } else if (filter[i].filterType == "greaterLowerEqualDate") {
              // console.log("///////////");
              if (filter[i].value) {
                if (!item[filter[i].parents[0]]) {
                  helpVar = false;
                  // console.log(6);
                  continue;
                }
                if (filter[i].isDay == "from") {
                  // console.log("From!!! ++++++++++++++++");
                  if (
                    filter[i].value &&
                    filter[i].value.toString().length > 0 &&
                    new Date(filter[i].value) >
                      new Date(item[filter[i].parents[0]][filter[i].child])
                  ) {
                    helpVar = false;
                    // console.log(7);
                    continue;
                  }
                } else if (filter[i].isDay == "to") {
                  // console.log("TO!!! ++++++++++++++++");
                  // console.log("filter[i].value", filter[i].value);
                  // console.log(
                  //   "filter[i].value.toString().length",
                  //   filter[i].value.toString().length
                  // );
                  // console.log(
                  //   "new Date(filter[i].value)",
                  //   new Date(filter[i].value)
                  // );
                  // console.log(
                  //   "new Date(item[filter[i].parents[0]][filter[i].child])",
                  //   new Date(item[filter[i].parents[0]][filter[i].child])
                  // );
                  // console.log(
                  //   "compare",
                  //   new Date(filter[i].value) <
                  //     new Date(item[filter[i].parents[0]][filter[i].child])
                  // );
                  if (
                    filter[i].value &&
                    filter[i].value.toString().length > 0 &&
                    new Date(filter[i].value) <
                      new Date(item[filter[i].parents[0]][filter[i].child])
                  ) {
                    helpVar = false;
                    // console.log(8);
                    continue;
                  }
                }
              } else {
                continue;
              }
            }
          } else if (filter[i].depth == 2) {
          }
          if (!helpVar) break;
        }
        // console.log("I return Item Bool -----", helpVar);
        if (helpVar) {
          // console.log("I return Item -----");

          return item;
        }
      });
      // return items.filter(function(item){
      //   let helpVar = true;
      //   let uniqueAttributesTable = [];
      //   let uniqueAttributesJson = {};
      //   for (let i=0; i<filter.length; i++) {
      //     if(filter[i].depth==0){
      //       if( !filter[i].value || filter[i].value=='' ) {helpVar=true; continue;}
      //       if(!filter[i].filterType || filter[i].filterType=='textmatch'){
      //         if(!isNaN(filter[i].value)) {
      //           if ( !(item[filter[i].child].toString()).includes(filter[i].value) ) helpVar = false;
      //         } else {
      //           if( !(item[filter[i].child].toLowerCase()).includes(filter[i].value) ) helpVar = false;
      //         }
      //       } else if(filter[i].filterType=='findInArray'){
      //         if(filter[i].value && filter[i].value.toString().length>0){
      //           if ( !(item[filter[i].attribute]).includes(filter[i].value) ) helpVar = false;
      //         }
      //       }
      //     } else if (filter[i].depth==1) {
      //       if( !filter[i].value || filter[i].value=='' ) {helpVar=true; continue;}
      //       if(!filter[i].filterType || filter[i].filterType=='textmatch'){
      //         if ( !item[filter[i].parents[0]] || item[filter[i].parents[0]][filter[i].child]==null ) {
      //           helpVar = false;
      //         } else {
      //           if(!isNaN(filter[i].value)) {
      //             if ( !(item[filter[i].parents[0]][filter[i].child].toString()).includes(filter[i].value) ) helpVar = false;
      //           } else {
      //             if( !(item[filter[i].parents[0]][filter[i].child].toLowerCase()).includes(filter[i].value) ) helpVar = false;
      //           }
      //         }
      //       } else if (filter[i].filterType=='greaterLowerEqualDate'){
      //         if ( filter[i].value) {
      //           if (!item[filter[i].parents[0]]) { helpVar = false; continue; }
      //           if(filter[i].isDay == 'from'){
      //             if ( filter[i].value && filter[i].value.toString().length>0 ){
      //               console.log("filter[i].value",filter[i].value,"item[filter[i].parents[0]][filter[i].child]",item[filter[i].parents[0]][filter[i].child]);
      //             }
      //             if ( filter[i].value && filter[i].value.toString().length>0 && new Date(filter[i].value) > new Date(item[filter[i].parents[0]][filter[i].child]) ) {
      //               helpVar = false; continue;
      //             }
      //           } else if(filter[i].isDay == 'to'){
      //             if ( filter[i].value && filter[i].value.toString().length>0 && new Date(filter[i].value) < new Date(item[filter[i].parents[0]][filter[i].child]) ) {
      //               helpVar = false; continue;
      //             }
      //           }
      //         } else {
      //           continue;
      //         }
      //       }
      //     } else if (filter[i].depth==2) {

      //     }
      //     if (!helpVar) break;
      //   }
      //   if (helpVar) {
      //     console.log('I return Item -----', item);
      //     return item
      //   };
      // });
      // if (itemsToReturn.length === 0) {
      //   itemsToReturn = itemsToReturn.concat({
      //     poisonLocationInfo: {
      //       lat: 37.678418,
      //       long: 23.809007,
      //     },
      //   });
      // }
      // console.log("itemsToReturn", itemsToReturn);

      if (itemsToReturn.length > 0) {
        if (items.length === 30) {
          this.mapService.setNewIncid(true);
        } else {
          this.mapService.setOldIncid(true);
        }
        this.mapService.setFitBounds();
      } else {
        if (items.length === 30) {
          this.mapService.setNewIncid(false);
        } else {
          this.mapService.setOldIncid(false);
        }
        this.mapService.setFitBounds();
      }

      return itemsToReturn;
    }
  }
}
