import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Incident } from "../shared/incident";
import { Staticpage } from "../shared/staticpage";
import { Reportincident } from "../shared/reportincident";
// import { Category } from '../shared/category';
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { Recaptchatoken } from "./recaptchatoken";
// import { Recaptchatoken } from '../shared/Recaptchatoken';

@Injectable({
  providedIn: "root",
})
export class RestApiService {
  // apiURL = 'https://localhost:3079/api';
  // apiURL = 'http://localhost:3102/api';
  apiURL = "https://ad-birds.dd.softwebpages.com/api";

  constructor(private http: HttpClient) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  // HttpClient API get() method => Fetch employees list
  // getTours(): Observable<Tour> {
  //   return this.http.get<Tour>(this.apiURL + '/tours')
  //   .pipe(
  //     retry(1),
  //     catchError(this.handleError)
  //   )
  // }
  getIncidents(): Observable<Incident[]> {
    return this.http
      .get<Incident[]>(this.apiURL + "/incidents")
      .pipe(retry(1), catchError(this.handleError));
  }
  getStaticpages(): Observable<Staticpage[]> {
    return this.http
      .get<Staticpage[]>(this.apiURL + "/staticpages")
      .pipe(retry(1), catchError(this.handleError));
  }
  getOneIncident(id): Observable<Incident[]> {
    return this.http
      .get<Incident[]>(this.apiURL + "/edit-incident/" + id)
      .pipe(retry(1), catchError(this.handleError));
  }
  postReportIncident(item): Observable<Reportincident> {
    return this.http
      .post<Reportincident>(this.apiURL + "/report-incident", item)
      .pipe(retry(1), catchError(this.handleError));
  }
  postCheckRecaptchaToken(token: string): Observable<Recaptchatoken> {
    return this.http
      .post<Recaptchatoken>(this.apiURL + "/check-recaptcha-token", {
        recaptcha: token,
      })
      .pipe(retry(1), catchError(this.handleError));
  }
  // getTour(id): Observable<Tour> {
  //   return this.http.get<Tour>(this.apiURL + '/tours/' + id)
  //   .pipe(
  //     retry(1),
  //     catchError(this.handleError)
  //   )
  // }
  // getOnetour(id): Observable<Tour> {
  //   return this.http.get<Tour>(this.apiURL + '/tours/' + id + '/edit')
  //   .pipe(
  //     retry(1),
  //     catchError(this.handleError)
  //   )
  // }
  // getOnefooterpage(id): Observable<Footerpage> {
  //   console.log("2: " + this.apiURL + '/footerpages/' + id + '/edit');
  //   return this.http.get<Footerpage>(this.apiURL + '/footerpages/' + id + '/edit')
  //   .pipe(
  //     retry(1),
  //     catchError(this.handleError)
  //   )
  // }
  // createTour(tour): Observable<Tour> {
  //   return this.http.post<Tour>(this.apiURL + '/tours', JSON.stringify(tour), this.httpOptions)
  //   .pipe(
  //     retry(1),
  //     catchError(this.handleError)
  //   )
  // }
  // postCreatereservation(reservation): Observable<Reservation> {
  //   return this.http.post<Reservation>(this.apiURL + '/reservations/create', reservation , this.httpOptions)
  //   .pipe(
  //     retry(1),
  //     catchError(this.handleError)
  //   )
  // }
  // updateTour(id, tour): Observable<Tour> {
  //   return this.http.put<Tour>(this.apiURL + '/tours/' + id, JSON.stringify(tour), this.httpOptions)
  //   .pipe(
  //     retry(1),
  //     catchError(this.handleError)
  //   )
  // }
  // deleteTour(id){
  //   return this.http.delete<Tour>(this.apiURL + '/tours/' + id, this.httpOptions)
  //   .pipe(
  //     retry(1),
  //     catchError(this.handleError)
  //   )
  // }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
